import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";
import {
  cellEditorParamsValuesDefaultBoolean,
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
  valueFormatterSurface,
  valueFormatterTonnage,
  valueFormatterUnite,
} from "../../../config/gridUtils";

const getListValidationAdministrative = () => {
  if (AuthService.hasProfil(droits.profils.ADMINISTRATEUR)) {
    return [
      {
        value: 1,
        label: "Non validé administrativement",
        nomId: "idetatvalidationadministrative",
      },
      {
        value: 2,
        label:
          "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        nomId: "idetatvalidationadministrative",
      },
      {
        value: 3,
        label:
          "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        nomId: "idetatvalidationadministrative",
      },
      {
        value: 4,
        label: "Validé administrativement",
        nomId: "idetatvalidationadministrative",
      },
      {
        value: 5,
        label: "Pré-validé, en attente paiement cotisation",
        nomId: "idetatvalidationadministrative",
      },
    ];
  }
  return [
    {
      value: 16,
      label: "Non validé administrativement",
      nomId: "idetatvalidationadministrative",
    },
    {
      value: 17,
      label: "Validé administrativement",
      nomId: "idetatvalidationadministrative",
    },
  ];
};

export const newColumns = [
  {
    headerName: "Contrat",
    children: [
      {
        headerName: "Validé administrativement",
        field: "idetatvalidationadministrative",
        tooltipField: "libellevalidationadministrative",
        flex: 3,
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25) &&
          !AuthService.hasProfil(droits.profils.OS_COLLECTE_FINALE),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: getListValidationAdministrative(),
        },
        cellStyle: function (params) {
          if (params.data.valide === true) {
            return cellStyleSuccess;
          }
          if (params.value === 1) {
            return cellStyleDanger;
          }
          return cellStyleWarning;
        },
        cellRenderer: (params) => params.data.libellevalidationadministrative,
      },
      {
        headerName: "Numéro du contrat",
        field: "numerocontrat",
      },
      {
        headerName: "Date de dernière modification",
        field: "datedernieremodification",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        columnGroupShow: "open",
      },
      {
        headerName: "Organisme stockeur",
        field: "nomorganismestockeur",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Statut juridique",
        field: "exploitation.libellestatutjuridique",
        columnGroupShow: "open",
      },
      {
        headerName: "SIRET",
        field: "exploitation.siret",
      },
      {
        headerName: "Raison sociale",
        field: "exploitation.raisonsociale",
      },
      {
        headerName: "Code DC",
        field: "exploitation.codedc",
        editable:
          (AuthService.hasProfil(droits.profils.RESPO_OS) &&
            AuthService.hasOrganismeStockeur(3)) ||
          AuthService.hasProfil(droits.profils.OS_COLLECTE_FINALE) ||
          AuthService.hasProfil(droits.profils.ADMINISTRATEUR),
        columnGroupShow: "open",
      },
      {
        headerName: "Numéro UNILEVER",
        field: "exploitation.numerounilever",
        columnGroupShow: "open",
      },
      {
        headerName: "Localisation",
        field: "exploitation.ville",
        valueGetter: (params) =>
          params.data.exploitation.codepostal
            ? `${params.data.exploitation.codepostal} ${params.data.exploitation.ville}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "exploitation.codeos",
        editable: false,
        columnGroupShow: "open",
      },
      {
        headerName: "Zones de production",
        field: "exploitation.libellezoneproduction",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Parcelle",
    children: [
      {
        headerName: "Nom de la parcelle",
        field: "parcelle.nomparcelle",
      },
      {
        headerName: "Localisation parcelle",
        field: "parcelle.nomcommune",
        valueGetter: (params) =>
          params.data.parcelle?.codepostal
            ? `${params.data.parcelle.codepostal} ${params.data.parcelle.nomcommune}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Variété",
        tooltipField: "libellevariete",
        field: "idrefvariete",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [],
        },
        valueGetter: (params) => params.data.libellevariete,
        cellRenderer: (params) => params.data.libellevariete,
      },
      {
        headerName: "Type de production",
        field: "libelletypeproduction",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface réelle printemps (ha)",
        field: "surfaceprintemps",
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle automne (ha)",
        field: "surfaceautomne",
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle totale (ha)",
        field: "surfacetotale",
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Quantité de semences",
        field: "quantitesemence",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
      },
    ],
  },
  {
    headerName: "Silos",
    children: [
      {
        headerName: "Silo de livraison",
        field: "idsilo",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25) &&
          !AuthService.hasProfil(droits.profils.TECH_OS),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [],
        },
        cellStyle: (params) => {
          if (params.data.siloreprismillesimeprecedent) {
            return cellStyleWarning;
          }
          return cellStyleDefault;
        },
        valueGetter: (params) => params.data.idsilo,
        cellRenderer: (params) => params.data.nomsilo,
        filterValueGetter: (params) => params.data.nomsilo,
      },
      {
        headerName: "Silo définitif",
        field: "idsilodefinitif",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 26),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [],
        },
        valueGetter: (params) => params.data.idsilodefinitif,
        cellRenderer: (params) => params.data.nomsilodefinitif ?? "PAS DE SILO",
        filterValueGetter: (params) =>
          params.data.nomsilodefinitif ?? "PAS DE SILO",
        cellStyle: (params) => {
          if (params.data.siloreprismillesimeprecedent) {
            return cellStyleWarning;
          }
          return cellStyleDefault;
        },
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Rendements / Tonnages",
    children: [
      {
        headerName: "Rendement de référence moyen",
        field: "exploitation.rendementrefmoyen",
        valueFormatter: (params) => valueFormatterUnite(params, "T/ha"),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
      },
      {
        headerName: "Tonnage de référence moyen",
        field: "tonnage",
        valueFormatter: valueFormatterTonnage,
      },
      {
        headerName: "Rendement estimé de l'année",
        field: "exploitation.rendementrefmillesime",
        valueFormatter: (params) => valueFormatterUnite(params, "T/ha"),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 26),
      },
      {
        headerName: "Tonnage estimé de l'année",
        field: "tonnageestime",
        cellStyle: cellStyleDefault,
        valueFormatter: valueFormatterTonnage,
      },
    ],
  },
  {
    headerName: "Traçabilité",
    children: [
      {
        headerName: "Traçabilité conforme",
        field: "estconforme",
        cellDataType: "boolean",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: cellEditorParamsValuesDefaultBoolean,
        },
        cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
        cellRenderer: (params) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
      },
      {
        headerName: "Surveillance (Colza/Sanve)",
        field: "surveillancecolzasanve",
        cellDataType: "boolean",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_PLAN_PRODUCTION,
            25,
          ) &&
          !AuthService.hasProfil(droits.profils.TECH_OS) &&
          !AuthService.hasProfil(droits.profils.RESPO_OS),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: cellEditorParamsValuesDefaultBoolean,
        },
        cellStyle: (params) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleDanger,
            cellStyleSuccess,
            cellStyleWarning,
          ),
        cellRenderer: (params) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        columnGroupShow: "open",
      },
      {
        headerName: "Récoltée",
        field: "recoltee",
        cellDataType: "boolean",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: cellEditorParamsValuesDefaultBoolean,
        },
        cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
        cellRenderer: (params) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        columnGroupShow: "open",
      },
      {
        headerName: "Autorisation stockage nettoyage",
        field: "autorisationstockagenettoyage",
        cellDataType: "boolean",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_PLAN_PRODUCTION,
            25,
          ) &&
          !AuthService.hasProfil(droits.profils.TECH_OS) &&
          !AuthService.hasProfil(droits.profils.RESPO_OS),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: cellEditorParamsValuesDefaultBoolean,
        },
        cellStyle: (params) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleDanger,
            cellStyleSuccess,
            cellStyleWarning,
          ),
        cellRenderer: (params) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        columnGroupShow: "open",
      },
    ],
  },
];
