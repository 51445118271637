import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import MentionsLegales from "./dashboard/MentionsLegales";
import ProducteurWidget from "./dashboard/widgets/ProducteursWidget";
import SurfaceEngageeWidget from "./dashboard/widgets/SurfaceEngageeWidget";
import InformationsExploitationWidget from "./dashboard/widgets/InformationsExploitationWidget";
import InformationMillesime from "./dashboard/widgets/InformationMillesime";
import StoreContext from "../context/StoreContext";
import droits from "../config/CodesDroits";
import { createNotification, RenderIf } from "../config/utils";
import WidgetCarto from "./dashboard/widgets/WidgetCarto";
import ExploitationController from "../config/apiUtils/ExploitationController";
import PlanProductionController from "../config/apiUtils/PlanProductionController";
import { IContrat } from "../config/types/contrat";
import WidgetDocuments from "./dashboard/widgets/WidgetDocuments";

export default function Dashboard(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [showMentionsLegales, setShowMentionsLegales] = useState<boolean>(true);
  const [hasShownMessage, setHasShownMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const flex: string = "300px";

  const closeMentionsLegales = () => {
    setShowMentionsLegales(false);
    localStorage.setItem("hideMentionsLegales", "true");
  };

  const loadData = async () => {
    setLoading(true);
    if (
      context.utilisateur.hasDroits(
        droits.typesdroits.GESTION_CONTRAT,
        droits.modalite.lecture,
      )
    ) {
      const resContratsExploitation =
        await ExploitationController.getContratExploitation(
          context.millesime.idmillesime,
          context.exploitation.informations.idexploitation,
        );

      context.setContratsExploitation(resContratsExploitation);

      if (
        resContratsExploitation.filter((c: IContrat) => !c.abandon).length === 0
      ) {
        context.detailContratsExploitation.setNbParcelles(0);
        context.detailContratsExploitation.setDetails([]);
      } else {
        const details = await PlanProductionController.getDetailsContrats(
          resContratsExploitation
            .filter((c: IContrat) => !c.abandon)
            .map((c: IContrat) => c.idcontrat),
        );
        context.detailContratsExploitation.setNbParcelles(details.length);
        context.detailContratsExploitation.setDetails(details);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (context.utilisateur.informations?.isProducteur && !hasShownMessage) {
      setHasShownMessage(true);
      createNotification(
        "info",
        "Informations",
        "Pour créer vos intentions de semis et vos contrats, cliquez sur le bouton 'Ma carto' dans la carte en haut à gauche de l'écran.",
        60000,
      );
    }
  });

  return (
    <>
      <Row>
        <Col>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Col style={{ marginBottom: 30, flex: "1 1 800px" }}>
              <WidgetCarto />
            </Col>
            <RenderIf
              isTrue={
                context.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_EXPLOITATION,
                  droits.modalite.lecture,
                ) && context.exploitation.informations?.idexploitation != -1
              }
            >
              <Col style={{ marginBottom: 30, flex: "1 1 " + flex }}>
                <InformationsExploitationWidget />
              </Col>
            </RenderIf>
            <RenderIf
              isTrue={
                context.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONTRAT,
                  droits.modalite.lecture,
                ) && context.exploitation.informations?.idexploitation != -1
              }
            >
              <Col style={{ marginBottom: 30, flex: "1 1 " + flex }}>
                <InformationMillesime loading={loading} />
              </Col>
            </RenderIf>
            <RenderIf
              isTrue={
                context.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_PRODUCTEUR,
                  droits.modalite.lecture,
                ) && context.exploitation.informations?.idexploitation != -1
              }
            >
              <Col style={{ marginBottom: 30, flex: "1 1 " + flex }}>
                <ProducteurWidget />
              </Col>
            </RenderIf>
            <RenderIf
              isTrue={
                context.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONTRAT,
                  droits.modalite.lecture,
                ) && context.exploitation.informations?.idexploitation != -1
              }
            >
              <Col style={{ marginBottom: 30, flex: "1 1 " + flex }}>
                <SurfaceEngageeWidget loading={loading} />
              </Col>
            </RenderIf>
            <RenderIf
              isTrue={
                context.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_EXPLOITATION,
                  droits.modalite.lecture,
                ) && context.exploitation.informations?.idexploitation != -1
              }
            >
              <Col style={{ marginBottom: 30, flex: "1 1 800px" }}>
                <WidgetDocuments />
              </Col>
            </RenderIf>
          </Row>
        </Col>
      </Row>
      <RenderIf isTrue={showMentionsLegales}>
        <Row>
          <Col>
            <RenderIf
              isTrue={localStorage.getItem("hideMentionsLegales") != "true"}
            >
              <MentionsLegales closeMentionsLegales={closeMentionsLegales} />
            </RenderIf>
          </Col>
        </Row>
      </RenderIf>
    </>
  );
}
